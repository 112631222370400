<template>
  <transition name="fade">
    <div class="modal modal-wrapper">
      <div class="modal__backdrop" @click="close"/>

      <div class="modal__dialog">
        <div class="modal__header">
          <span @click="close" class="pointer">&#10006;</span>
          <slot name="header"/>
        </div>
        <div class="modal__body">
          <form action="" @submit.prevent="saveAnswer">

            <md-card-content>
              <p class="field-name activity-form">{{ $t('activityForm') }}</p>
              <md-field class="md-text description-text">
                <md-textarea v-model="description" required></md-textarea>
              </md-field>

            </md-card-content>
            <div v-if=" answer.documents && answer.documents.length" class="files-wrapper">
              <div
                  v-for="(file, i) in answer.documents"
                  :key="i"
                  class="file-box"
              >
                <div class="close"><img src="@/assets/images/close.svg" @click="removeDocument(file.id)"/></div>
                <div @click="download(file)" class="file-info">
                  <img src="@/assets/images/file.svg"/>
                  <p class="file-name">{{ file.name }}</p>
                </div>
                <p>{{ getUpdatedDate(file.updated_at) }}</p>
              </div>
            </div>

            <md-card-content class="form-field flex-center upload-field">
              <p class="field-name upload-document">{{ $t('addFiles') }}</p>
              <md-field :class="{'uploaded': hasUploadedFile}">
                <md-file
                    @change="uploadDocument"
                    :placeholder="$t('selectFile') "
                    class="uploadDocumentEdit"
                    :value="fileName"
                    multiple
                    files="true"
                ></md-file>
              </md-field>
            </md-card-content>
            <p v-if="fileErrors[0]" class="fileErrors">{{ fileErrors[0] }}</p>

            <md-card-content v-if="messages.length || Object.entries(errors).length">
              <p class="field-name"></p>
              <Messages :messages="messages"></Messages>
              <Errors :errors="errors"></Errors>

            </md-card-content>

            <div class="actions">
              <md-button type="submit" class="save btn-action"><span>{{ $t('add') }}</span></md-button>
              <md-button class="cancel btn-action" @click="cancel">
                <span>{{ $t('cancel') }}</span>
              </md-button>
            </div>

          </form>
        </div>
        <div class="modal__footer">
          <slot name="footer"/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {errors} from "@/mixins/errors";
import {messages} from "@/mixins/messages";
import {mapActions, mapGetters} from "vuex";
import Errors from "@/components/Errors";
import Messages from "@/components/Messages";

export default {
  name: 'create-or-edit-answer',
  props: [
    'type',
    'answer',
  ],

  mixins: [
    errors,
    messages,
  ],
  components: {
    Errors,
    Messages
  },
  data() {
    return {
      description: null,
      documents: [],
      fileName: '',
      fileErrors: [],
      allDocuments: [],
      documents_id: [],
      hasNewFile: false
    }

  },

  computed: {
    ...mapGetters(['userData']),
    hasUploadedFile() {
      return this.documents_id.length > 0
    },

  },

  async mounted() {
    await this.getUserInfo()
    this.description = this.type === 'edit' ? this.answer.description : ''
    this.getDocumentsData()
  },

  methods: {
    ...mapActions(['createRequestAnswer', 'updateRequestAnswer', 'getUserInfo', 'getAnswerCurrent']),
    close() {
      this.$emit('close');
    },

    cancel() {
      this.close();
    },

    getDocumentsData() {
      const ids = []
      if (this.answer.documents && this.answer.documents.length) {
        this.answer.documents.forEach(item => {
          if (item.id) {
            ids.push(item.id)
          }
        })
      }
      this.documents_id.push(...ids)
    },

    async saveAnswer() {
      this.clearErrors();
      this.clearMessages();
      const response = await this.uploadFile();
      if (response !== 'success') {
        return
      }
      const data = {
        description: this.description,
        requests_id: this.$route.params.id,
        user_id: this.userData.id,
        documents_id: this.documents_id
      }
      const payload = {
        data,
        id: this.answer.id
      }
      if (this.type === 'edit') {
        await this.updateRequestAnswer(payload)
      } else {
        await this.createRequestAnswer(data)
      }
      this.$emit('close', 'saved')

    },

    removeDocument(id) {
      let conf = confirm("Are you sure?");

      if (!conf) {
        return;
      }
      this.$axios
          .delete(process.env.VUE_APP_API_URL + "/requests/documents/" + id + "/delete")
          .then(() => {
            this.getAnswerCurrent(this.$route.params.id)
          })
          .then(() => this.documents = this.answer.documents)

          .catch(error => {
            console.log(error)
          })
    },

    async uploadDocument() {
      9
      this.fileErrors = []
      if (event.target.files) {
        let bigSize = false;
        event.target.files.forEach(file => {
          const size = file.size / 1024 / 1024;
          if (size > 25) {
            bigSize = true;
          }
        })

        if (bigSize) {
          this.fileErrors[0] = 'The maximum size for a file is 25 Mb';
          event.target.value = '';
          this.fileName = 'Select file';
          this.documents = [];
        } else {
          this.documents.push(...event.target.files);
          this.fileErrors = [];
        }
      }
    },

    getUpdatedDate(date) {
      return this.$moment(date).format('DD/MM/YYYY')
    },

    async uploadFile() {
      const url = process.env.VUE_APP_API_URL + '/requests/documents/create/several';
      const formData = new FormData();

      this.hasNewFile = false
      this.documents.forEach(file => {
        if (!file.id) {
          this.hasNewFile = true
          formData.append(`document[]`, file);
          this.fileName = 'Loading...';
        }
      })

      if (!this.hasNewFile) {
        return 'success'
      }

      return await this.$axios
          .post(url, formData)
          .then(response => {
            this.hasNewFile = false
            this.documents.forEach(file => {
              this.allDocuments.push(file)
            })
            this.fileName = 'Select file';
            this.documents_id.push(...response.data.ids);

            this.allDocuments.map((doc, i) => {
              doc.id = this.documents_id[i]
            })
            return response.data.status
          })
          .catch(err => {
            this.hasNewFile = false
            this.documents = [];
            this.fileName = 'Select file';
            const errors = err.response.data.errors;
            for (let errorsKey in errors) {
              this.documents = [];
              this.fileErrors.push(errors[errorsKey].toString())
            }
            // this.loading = false;
          })
    },

    download(file) {
      if (!file.id) return;
      this.$axios({
        url: process.env.VUE_APP_API_URL + `/requests/documents/${file.name}/downloadFile`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', file.name);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },

  },
};

</script>
<style lang="scss">
.modal-wrapper {
  .files-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-left: 15px;

    .file-box {
      width: 120px;
      background-color: #ffffff;
      padding: 10px 15px;
      border: 1px solid #D8D8D8;
      border-radius: 5px;
      margin-right: 10px;
      margin-bottom: 10px;


      .close {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;

        img {
          cursor: pointer;
        }
      }

      .file-info {
        display: flex;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
      }

      .file-name {
        text-decoration: none;
        margin: 10px 0 5px;
        font-weight: normal;
        font-size: 12px;
        line-height: 1;
        word-break: break-all;
        /*height: 28px;*/
        width: 100px;
        text-align: center;
        color: black;
      }
    }
  }

  .md-input {
    height: 50px !important;
    padding: 0 0 0 13px !important;
    margin-right: 50px !important;
  }
}

.description-text {
  padding-top: -16px !important;
  margin-left: 11px !important;
}

.md-card-content {
  padding: 10px !important;
}

.md-field.md-theme-default:after {
  background-color: unset !important;
}

.md-field.md-has-textarea:not(.md-autogrow):after, .md-field.md-has-textarea:not(.md-autogrow):before {
  border: 1px solid #CDD0D9;
  box-sizing: border-box;
  border-radius: 6px;
}

.md-field.md-theme-default.md-has-textarea:not(.md-autogrow):after {
  border-color: #CDD0D9;
}

.upload-file-answer {
  margin-top: -40px;
}

.uploadDocument {
  border: 1px solid #CDD0D9;
  box-sizing: border-box;
  border-radius: 6px;


  input {
    height: 40px !important;
    width: 342px !important;

  }

  i {
    position: absolute !important;
    right: 0 !important;
    height: 48px;
    border-left: 1px solid #CDD0D9;
    width: 12%;
  }

  .md-icon-image svg {
    flex: unset !important;
  }
}
</style>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}

.md-card-content {
  display: flex;
}

.activity-form {
  margin-top: 21px;
}

.upload-document {
  margin-left: 25px;
  margin-right: 12px;
  display: flex;
  align-items: center;
}

.md-text[data-v-877c9bce] {
  margin-left: 10px;
}

.modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;

  &__backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &__dialog {
    background-color: #ffffff;
    position: relative;
    width: 580px;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    z-index: 2;
    @media screen and (max-width: 992px) {
      width: 90%;
    }
  }

  &__close {
    width: 30px;
    height: 30px;
  }


  &__header {
    padding: 20px 20px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }

  &__body {
    padding: 0px 20px 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__footer {
    padding: 10px 20px 20px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.name-disabled {
  background: #c6c6c6;
  opacity: .5;
}

.md-field, .select-wrapper {
  width: 352px !important;
}

.md-text {
  width: 352px !important;
  margin-top: 21px;
}

.md-textarea {
  width: 352px !important;
  height: 155px !important;
}

.edit-text {
  margin-top: 0;
}

.actions {
  display: flex;
  margin-left: 101px;
  height: 50px;

  @media(max-width: 1440px) {
    display: flex;
    justify-content: space-between;
  }

  .btn-action {
    width: 142px;
    height: 48px;
    margin: 0;
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    border-radius: 6px;
    text-transform: capitalize;
  }

  .save {
    background: #EC8B31 !important;

    span {
      color: white;
    }
  }

  .cancel {
    margin-left: 10px;
    box-shadow: none;
    border: 1px solid #CDD0D9;
    border-radius: 6px;

    @media(max-width: 1440px) {
      margin-left: 0;
    }

    span {
      color: black;
    }
  }
}
</style>