<template>
  <div class="requests-form">
    <div>
      <h2 class="edit-request-header">{{ $t('request') }}</h2>
      <div class="request-edit-body">
        <div class="left-section">
            <form @submit.prevent="saveRequest">
            <md-field class="title-field hidden-title-field">
              <md-input :disabled="selected_status === 'Accepted'" v-model="title" @focus="activateSaveButton"
                        class="input-title"></md-input>
              <span class="generic-date-number">{{ genericNumber }}</span>
            </md-field>
            <md-field>
              <md-textarea :disabled="selected_status === 'Accepted'" v-model="description" @focus="activateSaveButton" required :placeholder="$t('reqDesc') "
                           class="textarea-description"></md-textarea>
            </md-field>
            <div v-if="request.documents" class="files-wrap">
              <div
                  v-for="(file, i) in request.documents"
                  :key="i"
                  class="file-box"
              >
                <div class="close"><img src="@/assets/images/close.svg" @click="removeDocument(file.id)"/></div>
                <div @click="download(file)" class="file-info">
                  <img src="@/assets/images/file.svg"/>
                  <p class="file-name">{{ file.name }}</p>
                </div>
                <p>{{ getUpdatedDate(file.updated_at) }}</p>
              </div>
            </div>
            <md-button class="save-button"
                       v-if="showSaveButton && focus &&  selected_status !== 'Accepted'"
                       type="submit">
              <span>{{ $t('save') }}</span>
            </md-button>
            <h3 v-if="answer.description" class="title-answer">{{ $t('answer') }}</h3>
            <!--                <h4 v-if="answer.updated_at" class="answer-user-name">{{answer.user && answer.user.name}} {{momentDate(answer.updated_at, 'ans')}}</h4>-->
            <p class="answer-description">{{ answer.description }}</p>

            <div v-if="answer.documents" class="files-wrap">
              <div
                  v-for="(file, i) in answer.documents"
                  :key="i"
                  class="file-box"
              >
                <div class="close"><img src="@/assets/images/close.svg" @click="removeAnswerDocument(file.id)"/></div>
                <div @click="download(file)" class="file-info">
                  <img src="@/assets/images/file.svg"/>
                  <p class="file-name">{{ file.name }}</p>
                </div>
                <p>{{ getUpdatedDate(file.updated_at) }}</p>
              </div>
            </div>

            <md-button class="answer-button" v-if="userData
                            && userData.roles[0].name !== 'client'
                            && !Object.entries(answer).length
                            && selected_status !== 'Closed'
                            && selected_status !== 'Accepted'"
                       @click="openAnswerModal('create')">
              <span class="add-answer-text">{{ $t('addAnswer') }}</span>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M8 1C8 0.447715 7.55228 0 7 0C6.44772 0 6 0.447715 6 1V6H1C0.447715 6 0 6.44772 0 7C0 7.55228 0.447715 8 1 8H6V13C6 13.5523 6.44772 14 7 14C7.55228 14 8 13.5523 8 13V8H13C13.5523 8 14 7.55228 14 7C14 6.44772 13.5523 6 13 6H8V1Z"
                      fill="#EC8B31"/>
              </svg>
            </md-button>
            <md-button class="answer-button"
                       v-if="userData && userData.roles[0].name !== 'client' && Object.entries(answer).length && selected_status !== 'Accepted' "
                       @click="openAnswerModal('edit')">
              <span>{{ $t('edit') }}</span>
            </md-button>
          </form>
          <form @submit.prevent="saveComment">
            <h3 class="title-comment">{{ $t('comments') }}</h3>
            <div v-for="comment in comments" :key="comment.id">
              <div class="comment-name-section">
                <img src="../../assets/images/person.png" alt="">
                <h4>{{ comment.requests && comment.user.name }} {{ momentDate(comment.created_at, 'ans') }}</h4>
              </div>
              <p class="comment-text">{{ comment.comment_text }}</p>

              <div v-if="comment.documents.length > 0" class="files-wrap">
                <div
                    v-for="(file, i) in comment.documents"
                    :key="i"
                    class="file-box"
                >
                  <div @click="download(file)" class="file-info">
                    <img src="@/assets/images/file.svg"/>
                    <p class="file-name">{{ file.name }}</p>
                  </div>
                  <p>{{ getUpdatedDate(file.updated_at) }}</p>
                </div>
              </div>


            </div>
            <md-field>
              <md-textarea v-model="comment_text" required :disabled="selected_status === 'Accepted'" :placeholder="$t('addAComment')" class="textarea-description"
                           data-error="sdfsdfsdfsgdfgsdfg"></md-textarea>
            </md-field>

            <md-card-content class="form-field flex-center upload-field">
              <md-field :class="{'uploaded': hasUploadedFile}">
                <md-file
                    :disabled="selected_status === 'Accepted'"
                    @change="uploadDocument"
                    :placeholder="$t('selectFile') "
                    class="uploadDocumentEdit"
                    :value="fileName"
                    multiple
                    files="true"
                ></md-file>
              </md-field>
              <p v-if="fileErrors[0]" class="fileErrors">{{ fileErrors[0] }}</p>
            </md-card-content>


            <md-button v-if="selected_status !== 'Accepted'" class="md-raised cancel" type="submit" :disabled="selected_status == 'Closed'">
                {{ $t('send') }}
            </md-button>
          </form>
        </div>
        <div class="right-section" v-if="userData && userData.roles[0].name !== 'client'">
          <md-card-content class="select-wrap">
            <p class="field-name">{{ $t('status') }}</p>
            <select name="statuses"  class="select-statuses" @change="saveSelectedStatus()" v-model="selected_status"
                    :disabled="userData && userData.roles[0].name === 'client'">
              <option
                  v-for="(status,i) in statusesList"
                  :key="status"
                  :id="`approved${i}`"
                  :disabled="statusDisabled(status)"
                  :data-key="i"
                  :value="status"
              >{{status}}</option>
            </select>
          </md-card-content>
          <p class="request-info-title">{{ $t('createdBy') }}</p>
          <p>{{ $t(request.user_name) }}</p>
          <p class="request-info-title">{{ $t('priority') }}</p>
          <p>{{ $t(request.priority) }}</p>
          <p class="request-info-title">{{ $t('nature') }}</p>
          <p>{{ $t(request.nature) }}</p>
          <p class="request-info-title" v-if="request.last_edited">{{ $t('lastEdited') }}</p>
          <p v-if="request.last_edited">{{ request.last_edited }}
            {{ request.last_edited && momentDate(request.updated_at, 'req') }}</p>
          <p class="request-info-title" v-if="request.created_at" >{{ $t('createData') }}</p>
          <p>{{momentDate(request.created_at, 'req')}}</p>
          <p class="request-info-title">{{ $t('requestAnswerDate') }}</p>
          <p>{{ momentDate(answer.created_at, 'req') }}</p>
          <p class="request-info-title">{{ $t('client') }}:</p>
          <p>{{ request.client_name }}</p>
            <p class="request-info-title">{{ $t('verificationLevel') }}</p>
            <select :disabled="selected_status === 'Accepted'" name="verifications" class="select-statuses" @change="saveRequest()" v-model="selected_verification"
            >
                <option v-for="(verification) in verificationsList" :key="verification" :value="verification">
                    {{ (verification) }}
                </option>
            </select>

        </div>
        <div class="right-section" v-else>
          <md-card-content class="select-wrap">
            <p class="field-name">Status</p>
            <select @change="saveRequest" name="statuses"  class="select-statuses" v-model="selected_status"
                   >
              <option :disabled="selected_status === 'Accepted'" v-for="(status,i) in statusesList" :key="status" :id="`approved${i}`" :value="status">{{ status }}</option>
            </select>
          </md-card-content>
          <p class="request-info-title">{{ $t('Request') }}:</p>
          <p>{{ request.request_date }}</p>
          <p class="request-info-title" v-if="request.last_edited">{{ $t('LastEdited') }}:</p>
          <p v-if="request.last_edited">{{ request.last_edited }}
            {{ request.last_edited && momentDate(request.updated_at, 'req') }}</p>
          <p class="request-info-title">{{ $t('Created') }}:</p>
          <p>{{ request.user_name }}</p>
          <p class="request-info-title">{{ $t('Nature') }}:</p>
          <p>{{ $t(request.nature) }}</p>
          <p class="request-info-title">{{ $t('Priority') }}:</p>
          <p>{{ $t(request.priority) }}</p>
            <p class="request-info-title">{{ $t('verificationLevel') }}</p>
            <select :disabled="selected_status === 'Accepted'" @change="saveRequest" name="verifications" class="select-statuses" v-model="selected_verification"
            >
                <option v-for="(verification) in verificationsList" :key="verification" :value="verification">
                    {{ verification }}
                </option>
            </select>
        </div>
      </div>
      <div>
        <Messages :messages="messages"></Messages>
        <Errors :errors="errors"></Errors>
        <CreateOrEditAnswer
            v-if="isModalVisible"
            @close="closeModal"
            :type="createOrEdit"
            :answer="answer"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {errors} from "@/mixins/errors"
import {messages} from "@/mixins/messages"
import {printArray} from "@/mixins/printArray"
import {userPermissions} from '@/mixins/userPermissions';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import Errors from "@/components/Errors";
import Messages from "@/components/Messages";
import CreateOrEditAnswer from "@/components/modal/CreateOrEditAnswer";

export default {
  name: "requests-form-edit",
  mixins: [
    printArray,
    errors,
    messages,
    userPermissions
  ],
  components: {
    Errors,
    Messages,
    CreateOrEditAnswer,
  },
  data: () => ({
    requestDocument: [],
    selected_status: '',
    selected_verification: '',
    statuses: [],
    description: '',
    title: '',
    activeClientList: [],
    isModalVisible: false,
    createOrEdit: '',
    comment_text: '',
    showSaveButton: true,
    focus: false,
    documents: [],
    fileName: '',
    fileErrors: [],
    allDocuments: [],
    comment_documents_id: [],
    commentDocuments: [],
    request_documents_id: [],
    genericNumber: null,
    genericTitle: null
  }),

  computed: {
    ...mapGetters(['statusesList',
      'selectedRequest',
      'verificationsList',
      'userData',
      'activeClients',
      'requestsAnswer',
      'answer',
      'user',
      'comments']),

    request() {
      return this.selectedRequest
    },
    hasUploadedFile() {
      return this.comment_documents_id.length > 0
    },
    // disabledStatus() {
    //   let data
    //   if (this.userData.roles[0].name !== 'client') {
    //     this.statusesList.map(item => {
    //       if (item === 'Closed') {
    //         data= false
    //       }
    //       else {
    //         data = true
    //       }
    //     })
    //   }
    //   return data
    // },
  },

  watch: {
    $route() {
      this.clearErrors()
      this.clearMessages()
    },

    userData: {
      deep: true,
      handler() {
        this.getStatusesList()
        this.getVerificationsList()
        this.selected_status = this.$t(this.request.status)
        // this.selected_verification = this.$t(this.request.verification_level)

        if ( this.userData.language === 'ru' ){
          this.selected_verification = this.$t(this.request.verification_level)
        }
        if ( this.userData.language === 'en' ){
          this.selected_verification = this.$t(this.request.verification_level)
        }
      }
    },

    selected_status(val) {
      (val === 'Closed' || val === 'Processing') ?
          this.showSaveButton = false : this.showSaveButton = true
      // if (val !== this.request.status) {
      //   this.saveRequest()
      // }
    },
    //   selected_verification(val) {
    //     console.log(val)
    //   // if (val !== this.request.verification_level) {
    //   //   this.saveRequest()
    //   // }
    // },
    genericNumber(val) {
      if(val){
        setTimeout(() => {
          const newWidth = document.getElementsByClassName('generic-date-number')[0].clientWidth
          document.getElementsByClassName('input-title')[0].style.paddingLeft = newWidth + 15 +"px";
          document.getElementsByClassName('hidden-title-field')[0].style.opacity = 1;
        }, 500);
      }
    }

  },

  beforeDestroy() {
    this.description = '';
    this.title = '';
    this.setComments([]);
    this.setAnswerCurrent([]);
    this.setLastEditor(null)
  },
    async created() {
        await this.getUserInfo()
        await this.getStatusesList()
    },

  async mounted() {
    this.setRequest({})
    await this.getAnswerCurrent(this.$route.params.id)
    await this.getRequestData()
    await this.getVerificationsList()
    await this.getRequest(this.$route.params.id)
    // await this.checkRole()
    // await this.checkRoleClient()
    await this.getComments(this.$route.params.id)
    this.selected_status = this.$t(this.request.status)
    this.selected_verification = this.$t(this.request.verification_level)

      if (this.userData.roles[0].name === 'manager') {

          if (this.selected_status === 'New' || this.selected_status === 'Processing' || this.selected_status === 'Waiting for answer') {
              document.getElementById('approved5').removeAttribute('disabled')

          } else {
              document.getElementById('approved5').setAttribute('disabled', 'true')
          }
      }
      if (this.userData.roles[0].name === 'client') {
          document.getElementById('approved5').setAttribute('disabled', 'true')
          if (this.selected_status === 'Waiting for details') {
              document.getElementById('approved6').removeAttribute('disabled')

          } else {
              document.getElementById('approved6').setAttribute('disabled', 'true')
          }
      }

      if (this.userData.roles[0].name === 'manager') {

          document.getElementById('approved6').setAttribute('disabled', 'true')

          if (this.selected_status !== 'Approved') {
              document.getElementById('approved3').setAttribute('disabled', 'true')
              document.getElementById('approved4').setAttribute('disabled', 'true')
          } else {
              document.getElementById('approved4').removeAttribute('disabled')
          }
      }

    if (this.selected_status === 'Approved') {
      document.getElementById('approved4').removeAttribute('disabled')
    }

      if (this.title.indexOf(' ') > 0){
          this.genericNumber =  this.title.substr(0,this.title.indexOf(' '));
          this.title = this.title.substr(this.title.indexOf(' ')+1);
      }
      else{
          this.genericNumber =  this.title.substr(this.title.indexOf(' ')+1);
          this.title = '';
      }

  },

  methods: {
    ...mapActions([
      'getStatusesList',
      'getVerificationsList',
      'getRequest',
      'getRequestUpdate',
      'getUserInfo',
      'getActiveClients',
      'getAnswerCurrent',
      'getCommentSave',
      'getComments']),
    ...mapMutations(['setLastEditor', 'setRequest', 'setComments', 'setAnswerCurrent']),
    statusDisabled(status) {
      if (this.userData.roles[0].name !== 'client' && this.selected_status === 'Accepted' ){
        return status !== 'Closed'
      }
    },
    saveSelectedStatus () {
      (this.selected_status === 'Closed' || this.selected_status === 'Processing') ?
          this.showSaveButton = false : this.showSaveButton = true
      if (this.selected_status !== this.request.status) {
        this.saveRequest()
      }
    },

    async getRequestData() {
      await this.getRequest(this.$route.params.id)
          .then(() => {
            this.description = this.selectedRequest.description
            this.title = this.selectedRequest.title
            this.getActiveClients(this.selectedRequest.user_id)
                .then(() => this.activeClientList = this.activeClients)
          })
    },
      // checkRole(){
      //     if (this.userData.roles[0].name === 'admin' || this.userData.roles[0].name === 'client' && document.getElementById('approved3')) {
      //         document.getElementById('approved3').removeAttribute('disabled')
      //         document.getElementById('approved4').removeAttribute('disabled')
      //     }
      //     else if(this.userData.roles[0].name === 'manager'){
      //         if (this.selected_status !== 'Approved'){
      //             document.getElementById('approved3').setAttribute('disabled','true')
      //             document.getElementById('approved4').setAttribute('disabled','true')
      //         }else{
      //             document.getElementById('approved4').removeAttribute('disabled')
      //         }
      //     }
      // },

      // checkRoleClient(){
      //     if (this.userData.roles[0].name === 'client') {
      //         document.getElementById('approved0').setAttribute('disabled','true')
      //         document.getElementById('approved1').setAttribute('disabled','true')
      //         document.getElementById('approved2').setAttribute('disabled','true')
      //         document.getElementById('approved4').setAttribute('disabled','true')
      //     }
      // },
    activateSaveButton() {
      this.focus = true
    },
    momentDate(date, current = null) {
      if (current === 'req') {
        return date ? this.$moment(date).format('YYYY-MM-DD') : ''
      } else if (current === 'ans') {
        return date ? this.$moment(date).format('YYYY-MM-DD H:m:s') : ''
      }
    },

    async saveComment() {

      let response = 'success';
      if (this.documents.length) {
        response = await this.uploadFile();
      }
      if (response !== 'success') {
        return
      }
      let data = {
        comment_text: this.comment_text,
        requests_id: this.$route.params.id,
        user_id: this.userData.id,
        documents_id: this.comment_documents_id
      }
      if (this.comment_text && this.selected_status !== 'Closed') {
        await this.getCommentSave(data)
        this.getComments(this.$route.params.id)
        this.comment_text = ''
      }
      this.comment_documents_id = []
      this.documents = []
      this.allDocuments = []
    },
    openAnswerModal(type) {
      this.isModalVisible = true
      this.createOrEdit = type
    },
    closeModal(saved) {
      this.isModalVisible = false;
      if (saved) {
        this.getAnswerCurrent(this.$route.params.id);
      }
    },

    removeDocument(id) {
      let conf = confirm("Are you sure?");

      if (!conf) {
        return;
      }
      this.$axios
          .delete(process.env.VUE_APP_API_URL + "/requests/documents/" + id + "/delete")
          .then(() => {
            this.comments.map((value) => {
              value.documents = value.documents.filter(doc => doc.id !== id)
            })
            this.getRequest(this.$route.params.id)
          })

          .catch(error => {
            console.log(error)
          })
    },

    removeAnswerDocument(id) {
      let conf = confirm("Are you sure?");

      if (!conf) {
        return;
      }
      this.$axios
          .delete(process.env.VUE_APP_API_URL + "/requests/documents/" + id + "/delete")
          .then(() => {
            this.getAnswerCurrent(this.$route.params.id)
          })

          .catch(error => {
            console.log(error)
          })
    },

    async uploadDocument() {
      console.log(event.target.files)
      this.fileErrors = []
      if (event.target.files) {
        let bigSize = false;
        event.target.files.forEach(file => {
          const size = file.size / 1024 / 1024;
          if (size > 25) {
            bigSize = true;
          }
        })

        if (bigSize) {
          this.fileErrors[0] = 'The maximum size for a file is 25 Mb';
          event.target.value = '';
          this.fileName = 'Select file';
          this.documents = '';
        } else {
          this.documents = event.target.files;
          this.fileErrors = [];
        }
      }
    },

    getUpdatedDate(date) {
      return this.$moment(date).format('DD/MM/YYYY')
    },

    async uploadFile() {
      const url = process.env.VUE_APP_API_URL + '/requests/documents/create/several';
      const formData = new FormData();
      if (this.documents) {
        this.documents.forEach(file => {
          formData.append(`document[]`, file);
          this.fileName = 'Loading...';
        })
      }

      return await this.$axios
          .post(url, formData)
          .then(response => {
            this.documents.forEach(file => {
              this.allDocuments.push(file)
            })
            this.fileName = 'Select file';
            this.comment_documents_id = [...this.comment_documents_id, ...response.data.ids];
            this.allDocuments.map((doc, i) => {
              doc.id = this.comment_documents_id[i]
            })
            return response.data.status
          })
          .catch(err => {
            this.documents = null;
            this.fileName = 'Select file';
            const errors = err.response.data.errors;
            for (let errorsKey in errors) {
              this.documents = null;
              this.fileErrors.push(errors[errorsKey].toString())
            }
          })
    },

    download(file) {
      this.$axios({
        url: process.env.VUE_APP_API_URL + `/requests/documents/${file.name}/downloadFile`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', file.name);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },

    async saveRequest() {
      this.request.documents.forEach(el => {
        this.request_documents_id.push(el.id)
      })
      const data = {
        ...this.request,
        description: this.description,
        title: `${this.genericNumber} ${this.title}`,
        last_edited: this.userData.id,
        status: this.$t(this.selected_status),
        verification_level: this.$t(this.selected_verification),
        documents_id: this.request_documents_id
      }
      if (this.request.requests_related.length) {
        data.requests_related = this.request.requests_related.map(el => el.id)
      }
      await this.getRequestUpdate(data)
          .then(() => {
            this.$router.push({name: 'requests-list'})
          })

    }
  }
}
</script>
<style lang="scss">
.requests-form-edit .md-field .md-input {
  position: relative;
}
.hidden-title-field{
  opacity: 0;
}
.generic-date-number {
  position: absolute;
  top: 34px;
  left: 13px;
  font-size: 16px;
}
.md-button {
  text-transform: unset !important;
}

.fileErrors {
  color: red;
}

.files-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;

  .file-box {
    width: 120px;
    background-color: #ffffff;
    padding: 10px 15px;
    border: 1px solid #D8D8D8;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 10px;


    .close {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;

      img {
        cursor: pointer;
      }
    }

    .file-info {
      display: flex;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
    }

    .file-name {
      text-decoration: none;
      margin: 10px 0 5px;
      font-weight: normal;
      font-size: 12px;
      line-height: 1;
      word-break: break-all;
      /*height: 28px;*/
      width: 100px;
      text-align: center;
      color: black;
    }
  }
}

.md-button-content {
  display: flex;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: black;
}

.md-field .md-input {
  height: 48px !important;
}

.md-field {
  padding-top: 20px;
}

.requests-form .right-section {
  font-size: 16px;
}

.requests-form .left-section {
  font-size: 16px;
}

.uploadDocumentEdit {
  border: 1px solid #CDD0D9;
  box-sizing: border-box;
  border-radius: 6px;

  i {
    position: absolute !important;
    right: 0 !important;
    height: 48px;
    border-left: 1px solid #CDD0D9;
    width: 12%;
  }

  .md-icon-image svg {
    flex: unset !important;
  }
}
</style>

<style lang="scss" scoped>

.requests-form {
  padding: 0 35px 0 21px;

  .md-field.md-has-textarea:not(.md-autogrow):after, .md-field.md-has-textarea:not(.md-autogrow):before {
    border: 1px solid #CDD0D9;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .md-button.md-raised:not([disabled]) {
    box-shadow: unset;
    border: 1px solid #CDD0D9;
    box-sizing: border-box;
    border-radius: 6px;
  }


  .upload-field {
    padding: 0 !important;
  }


  .request-edit-body {
    display: flex;
    justify-content: space-between;
  }

  .add-answer-text {
    padding: 12px;
  }

  .answer-button {
    margin-bottom: 53px;

    svg {
      margin: 17px 17px 17px 0;
    }
  }

  .title-comment {
    margin-bottom: 15px;
  }

  .modal__dialog {
    width: 681px !important;
    height: 519px !important;
  }


  .left-section {
    width: 35%;
  }

  .right-section {
    width: 400px;
    padding: 30px 30px 30px 30px;
    height: 500px;
    background-color: #F0F5F7;
  }

  .select-wrap {
    margin-bottom: 15px;
  }

  .select-statuses {
    width: 70%;
    max-width: 418px;
    background: #FFFFFF;
    border: 1px solid #CDD0D9;
    outline: none;
    box-sizing: border-box;
    border-radius: 4px;
    height: 40px;
    padding: 0 0 0 15px;

    option {
      height: 50px;
    }
  }

  .answer-user-name {

  }

  .answer-description {
    margin-top: 10px;
    margin-bottom: 30px;
    margin-left: 10px;
  }

  .title {
    &-answer {
      margin-bottom: 10px;
      margin-top: 30px;
    }

    &-comment {
      margin-bottom: 10px;
      margin-top: 30px;
    }
  }

  .comment-text {
    margin-left: 10px;
    margin-bottom: 20px;
    font-size: 16px;
  }

  .comment-name-section {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    h4 {
      margin-left: 10px;
    }
  }


  .textarea-description {
    width: 260px;
  }

  .input-title {
    border: 1px solid #CDD0D9;
    box-sizing: border-box;
    border-radius: 6px;
    height: 40px;
    padding-left: 15px;
  }

  .md-field + .md-has-textarea:not(.md-autogrow) {
    margin-top: unset;
  }

  .select-wrap {
    padding: 0 !important;
  }

  .request-info-title {
    font-weight: bold;
    margin-top: 10px;
  }

  .edit-request-header {
    margin-bottom: 30px;
    font-size: 36px;
  }

  .md-field.md-has-textarea:not(.md-autogrow).md-focused .md-textarea, .md-field.md-has-textarea:not(.md-autogrow).md-has-value .md-textarea {
    padding-top: unset;
  }

  .save-button {
    background: #EC8B31;

    span {
      color: white;
    }
  }

  .answer-button {
    background: #f0f5f7;

    span {
      color: black;
    }

    svg {
      path {
        fill: black;
      }
    }
  }

  .answer-button, .save-button {
    border-radius: 6px;
    margin-left: 20px !important;
    display: flex;
    align-items: center;

    div {
      display: flex;
      align-items: center;
    }

    span {
      margin-right: 5px;
      font-family: Inter;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;

      text-align: center;
    }
  }
}

</style>

<style lang="scss">
.md-file {
  display: flex;
  flex-direction: column;
  height: 48px;
  flex: 1;
  input[type=file] {
    width: 100% !important;
    height: 48px !important;
    position: absolute;
    top: 21px;
    clip: auto;
    opacity: 0;
  }
}
</style>